import React, { useEffect, useState } from 'react';
import { ArrowDownOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Table, Button } from 'antd';

const EndNumberSelect = ({numbers,maxNumber, onChange}) => {

    const [selectedCells, setSelectedCells] = useState([]);
    const [selectedCellsNumbers, setSelectedCellsNumbers] = useState([]);


    

    
    //const numRows = 7; // Define the number of rows
    //const numCols = 7; // Define the number of columns

    useEffect(() => {

        if (numbers === null || numbers === undefined || numbers==="") {return}

       
        const numbersArray = numbers.split(',').map(Number);
        console.log("numbersArray", numbersArray)
        if (numbersArray.length === 0) {return}
        const uniqueSelectedCellsNumbers = [...new Set(numbersArray)];
        setSelectedCellsNumbers(uniqueSelectedCellsNumbers);

       
    }, []);

    useEffect(() => {
        const uniqueSelectedCellsNumbers = [...new Set(selectedCellsNumbers)];
        //setSelectedCellsNumbers(uniqueSelectedCellsNumbers);
        //console.log("uniqueSelectedCellsNumbers", uniqueSelectedCellsNumbers, uniqueSelectedCellsNumbers.sort(function (a, b) {  return a - b;  }))
        onChange(uniqueSelectedCellsNumbers.sort(function (a, b) {  return a - b;  }).join(','))
    }, [selectedCellsNumbers]);


    
    const generateEndButtons = () => {
    return Array.from({ length: 10 }, (_, i) => {
        const number = i;
        const isSelected = selectedCellsNumbers.includes(number);
        return (
            <Button 
                key={i} 
                onClick={() => handleEndClick(number)}
                type={isSelected ? "primary" : "default"}
                style={{
                    marginRight: '0px',
                    borderRadius: '0',
                    border: '1px solid',
                    display: 'inline-block',
                    fontSize: 12,
                    padding: 8
                }}
            >
                {number}
            </Button>
        );
    });
};

   const handleEndClick = (decadeIndex) => {
    setSelectedCellsNumbers(prev => {
        if (prev.includes(decadeIndex)) {
            return prev.filter(index => index !== decadeIndex);
        } else {
            return [...prev, decadeIndex];
        }
    });
};


    return (<>
        <div style={{maxWidth:550, maxHeight:400}}>
            {generateEndButtons()}
        </div>
        </>
    );
};

export default EndNumberSelect;