// RegistrationPage.js
import React, { useContext } from 'react';
import { Form, Input, Button, Checkbox, Select } from 'antd';
import LanguageContext from '../LanguageContext';
import { registerbeta } from '../library/account-helper';
import './css/Register.css';
const { Option } = Select;

const RegistrationPageBeta = () => {
  const { t } = useContext(LanguageContext);

  const onFinish = (values) => {
    //console.log('Success:', values);
    registerbeta({ email:values.email, password:values.password, onSuccess: () => { window.location.reload() }, onError: () => { console.log("error") } });
  };



  return (
    <div className="container">
      <p>Beta {t.register}</p>
      <Form
        name="registration"
        className="registration-form"
        onFinish={onFinish}
      >
        <Form.Item
            name="email"
            rules={[
                { required: true, message: t.emailwarning1 },
                { type: 'email', message: t.emailwarning1 }
            ]}
        >
            <Input placeholder={t.email} />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
    { required: true, message: t.passwordwarning1 },
    { 
      pattern: /^(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
      message: t.passwordwarning1
    }
  ]}
         
        >
          <Input.Password placeholder={t.password} />
        </Form.Item>
        <Form.Item
          name="agreement"
          valuePropName="checked"
          rules={[{ validator:(_, value) => value ? Promise.resolve() : Promise.reject(t.accepttermwarning) }]}
        >
          <Checkbox>{t.terms}</Checkbox>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {t.register}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default RegistrationPageBeta;