import React, { useEffect, useState } from 'react';

const NumberPattern = ({pattern, onChange}) => {
    const [selectedCell, setSelectedCell] = useState([0, 3]); // [row, column]
    const [selectedCells, setSelectedCells] = useState([]);
    const [lastSelectedRow, setLastSelectedRow] = useState(null);
    const [patternLabel, setPatternLabel] = useState(''); // [row, column
    const handleCellClick = (row, column) => {
        if (row === 0 || (lastSelectedRow !=null && row !==lastSelectedRow)) {
            return;
        }
        const isSelected = selectedCells.some(cell => cell[0] === row && cell[1] === column);
        let updatedSelectedCells = [];
        if (isSelected) {
            // Remove the selected cell
            updatedSelectedCells = selectedCells.filter(cell => cell[0] !== row || cell[1] !== column);
            setSelectedCells(updatedSelectedCells);
        } else {
            // Add the selected cell
            if (lastSelectedRow == null || lastSelectedRow == row) {
                updatedSelectedCells = [...selectedCells, [row, column]];
                setSelectedCells(updatedSelectedCells);
            }
        }

        
        const isAnyColumnSelected = updatedSelectedCells.some(cell => cell[0] === row);
        if (!isAnyColumnSelected) {
            setLastSelectedRow(null);
        }else{
            setLastSelectedRow(row);
        }
    };

    useEffect(() => {
        
        if (pattern === null || pattern === undefined) {
            return;
        }

        const rows = pattern.split(';');

        let selectedCellsArray = [];

        rows.forEach((row, rowIndex) => {
            const columns = row.split(',');
            if (rowIndex > 0) {
            columns.forEach((column) => {
                if (column !== ''){
                selectedCellsArray.push([rowIndex, Number(column) + 3]);
                setLastSelectedRow(rowIndex)
                }
            });
            }
        })
        setSelectedCells(selectedCellsArray);
    }, []);

    useEffect(() => {
    
        exportSelectedCells();
    }, [selectedCells]);

    const exportSelectedCells = () => {
        let rows = [];
        let setRows = 0;
        Array.from({ length: 7 }, (_, row) => {
            
            let columns = [];
            Array.from({ length: 7 }, (_, column) => {
                const isSelected = selectedCells.some(cell => cell[0] === row && cell[1] === column);
                if (isSelected || (row === 0 && column === 3)){
                    columns.push(column-3);
                }
                
                //console.log(`Row: ${row}, Column: ${column}, isSelected: ${isSelected}`);
            })
            if (columns.length > 0 ){
                setRows=row+1;
                
            }
            rows.push(columns.join(','));
        });
        
        rows = rows.slice(0, setRows);
        const exportedData = rows.join(';');

        onChange(exportedData)
        if (exportedData !== '0'){
        setPatternLabel(exportedData)
        }
       
    };
    return (<>
        <table style={{ border: '1px solid black', borderCollapse: 'collapse' }}>
            <tbody>
                {Array.from({ length: 7 }, (_, row) => (
                    <tr key={row}>
                        {Array.from({ length: 7 }, (_, column) => {
                            const isSelected = selectedCells.some(cell => cell[0] === row && cell[1] === column);
                            return (
                                <td
                                    key={column}
                                    onClick={() => handleCellClick(row, column)}
                                    style={{
                                        backgroundColor:
                                            row === 0 && column === 3 ? 'yellow' : isSelected ? 'lightblue' : (row !== lastSelectedRow && lastSelectedRow!=null) || row===0 ? 'lightgrey' : 'white',
                                        minWidth: '15px',
                                        minHeight: '15px',
                                        border: '1px solid black',
                                        height: '15px', // Set the height of the cells
                                        textAlign: 'center',
                                        fontSize: '10px',
                                        cursor: 'pointer', // Add this line to show the hand cursor
                                    }}
                                >
                                    {isSelected || (row === 0 && column === 3) ? 'X' : ''}
                                </td>
                            );
                        })}
                    </tr>
                ))}
            </tbody>
        </table>
        <p>{patternLabel}</p>
        </>
    );
};

export default NumberPattern;