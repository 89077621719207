import _axios from "axios";

import Cookies from "js-cookie";

export const userRequestClient = _axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });



export const postRequest = ({
    endpoint,
    data,
    callback,
    errorCallback = false,
    logging = false,
  }) => {

  

      //console.log("data", data);
      userRequestClient
        .post(endpoint, data, {
          headers: {
            Authorization: `Bearer ${Cookies.get("access_token")}`,
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers":
              "Origin, X-Requested-With, Content-Type, Accept",
          },
        })
        .then((response) => {
          callback(response.data);
        })
        .catch((error) => {
          if (errorCallback) {
            errorCallback(error);
          }
          if (logging) {
            console.error(error);
          }
        });
    
  };
  