// App.js
import React from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Login from './components/Login';
import Strategy from './components/Strategy';
import Draws from './components/DrawsPatterns';
import Statistics from './components/Statistics';
import Register from './components/Register';
import RegisterBeta from './components/RegisterBeta';
import Navigation from './components/Navigation';
import { GlobalContext } from './GlobalContext';
import LanguageContext from './LanguageContext';
import { translations, supportedLanguages } from './Translations';
import { checkLogin, getsubscriptionsactive, getsubscriptionPlans, getUserData } from './library/account-helper';
import { lotteryList } from './library/data-helper';
import SystemBuilder from './components/SystemBuilder';
import TestDrawer from './components/TestDrawer';
import ForgotPassword from './components/ForgotPassword';
import Subscribe from './components/Subscribe';
import SettingsPage from './components/Settings';
import Imprint from './components/Imprint';
import Privacy from './components/Privacy';
import Terms from './components/Terms';


function App() {
  const [globalDict, setGlobalDict] = useState({});
  const [language, setLanguage] = useState('en');
  const [lotteryListData, setLotteryListData] = useState(null);
  const [subscriptionsActive, setSubscriptionsActive] = useState(null);
  const [subscriptionPlans, setSubscriptionPlans] = useState(null);
  //const location = useLocation();
  
 const params = useParams();
  //console.log(params); // Should log { lang: 'value' }
  const { lang } = params;
  //console.log("lang2",lang);

  const contextValue = {
    language,
    setLanguage,
    t: translations[language]

  };

  const getGlobalValue = (key) => {
    return globalDict[key];
  };

  const setGlobalValue = (key, value) => {
    setGlobalDict(prevDict => ({
      ...prevDict,
      [key]: value
    }));
  };

  //console.log("getGlobalValue",getGlobalValue("loggedIn"));

  useEffect(() => {
    console.log("lang", lang)
    if (!lang) {
    var userLang = navigator.language || navigator.userLanguage; 
    userLang = userLang.split("-")[0];
  
    let selectedLanguage = 'en'; // Default to English
  
    // If the URL's language parameter is supported, use it
    if (lang && supportedLanguages.includes(lang)) {
      selectedLanguage = lang;
    }
    // Otherwise, if the browser's language is supported, use it
    else if (supportedLanguages.includes(userLang)) {
      selectedLanguage = userLang;
    }
  
    setLanguage(selectedLanguage);
  }
    setGlobalValue("loggedIn", checkLogin());
    
    setGlobalValue("refreshData", false);

    //window.location.href  = "/" + selectedLanguage;
  }, []);


  const LanguageSetter = ({ children }) => {
    const navigate = useNavigate();
    const { lang } = useParams();

    const sitepath = useLocation().pathname.split("/").pop();
    
   
      //);


    //console.log("children",children)
    //console.log("lang4",lang);
    useEffect(() => {
      
      if (!supportedLanguages.includes(lang)) {
      return navigate("/");
    }
      
      if (lang) {

        let title = translations[lang].sitetitle;
        console.log("sitepath",sitepath);
        if (translations[lang]['sitetitle'+sitepath] !== undefined) {
          title = translations[lang]['sitetitle'+sitepath];
        }

        document.title = title;

 
        var metaElement = document.querySelector('meta[name="description"]');
        metaElement.setAttribute("content", translations[lang].sitedescription);
 

        setLanguage(lang);
      }

    }, [lang, setLanguage]);

    return children;

    
};

  const routes = [
   
    { path: "/", element: <Home /> },
    { path: "strategy", element: <Strategy /> },
    { path: "patternfinder", element: <Draws /> },
    { path: "register", element: <Register /> },
    { path: "registerbeta", element: <RegisterBeta /> },
    { path: "systembuilder", element: <SystemBuilder /> },
    { path: "login", element: <Login /> },
    { path: "forgotpassword", element: <ForgotPassword /> },
    { path: "testdrawer", element: <TestDrawer /> },
    { path: "statistics", element: <Statistics /> },
    { path: "statistics/:tool", element: <Statistics /> },
    { path: "subscribe", element: <Subscribe /> },
    { path: "settings", element: <SettingsPage /> },
    { path: "imprint", element: <Imprint /> },
    { path: "privacy", element: <Privacy /> },
    { path: "terms", element: <Terms /> },
    { path: "*", element: <Home /> },
  ];


  useEffect(() => {

    if (lotteryListData==null) {
      lotteryList({setReturn:setLotteryListData});
    }

  if (lotteryListData !== null && lotteryListData !== undefined) {
   
    setGlobalValue('lotteryListData', lotteryListData);

  }
}, [lotteryListData]);

useEffect(() => {

  if ((subscriptionsActive == null && getGlobalValue("refreshData")!==undefined ) || getGlobalValue("refreshData")===true) {
    //console.log("refreshData", subscriptionsActive, getGlobalValue("refreshData"));
    getsubscriptionsactive({setReturn:setSubscriptionsActive});
    setGlobalValue("refreshData", false);
    return;
  }

  if (subscriptionsActive !== null && subscriptionsActive !== undefined) {
    setGlobalValue('subscriptionsActive', subscriptionsActive);
  }
}, [subscriptionsActive, getGlobalValue("refreshData")]);



useEffect(() => {

  if (subscriptionPlans == null) {
   
    getsubscriptionPlans({setReturn:setSubscriptionPlans});

  }

  if (subscriptionPlans !== null && subscriptionPlans !== undefined) {
   
    setGlobalValue('subscriptionPlans', subscriptionPlans);

  }

}, [subscriptionPlans]);

 useEffect(() => {

  if (!getGlobalValue("loggedIn") || !getGlobalValue("loggedIn")){ return}

  getsubscriptionsactive({setReturn:setGlobalValue("subscriptionsActive")});
  if (getGlobalValue("userData") === null || getGlobalValue("userData") === undefined) {
    getUserData({onSuccess: (data) => {setGlobalValue("userData", data)}, onError: (error) => {console.log(error)}});
  }
 }, [getGlobalValue("loggedIn")]);

  return (
    <LanguageContext.Provider value={contextValue}>
    <GlobalContext.Provider value={{ globalDict, getGlobalValue, setGlobalValue }}>
      
    <Router>
      <Navigation />
      <div style={{alignContent:'center', width:'100%', padding:5, margin: 'auto'}}>
      <Routes>
      
          {routes.map((route, index) => (
        <Route key={index} path={"/"+route.path} element={route.element} /> 
      ))}
      <Route
            path="/:lang/*"
            element={
              <LanguageSetter>
                <Routes>
                 {routes.map((route, index) => (
        <Route key={index} path={route.path} element={route.element} />
        
             
      ))}
              </Routes>
              </LanguageSetter>}
              />
            
      </Routes>
    </div>
    </Router>
    
    </GlobalContext.Provider>
    </LanguageContext.Provider>
  );
}

export default App;