
import { postRequest } from './middleware';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';




export const login = ({ email, password, onSuccess, onError }) => {
  postRequest({
    endpoint: '/login',
    data: {
      email,
      password,
    },
    callback: (response) => {
      if (response?.access_token === '') {
        onError('Login Unsuccessfull');
      } else {
        Cookies.set('access_token', response.access_token, {
          sameSite: 'none',
          secure: true,
        });
        onSuccess(response.message);
      }
    },
    errorCallback: (error) => onError(error),
  });
};

export const getUserData = ({onSuccess, onError }) => {
  postRequest({
    endpoint: '/user/info',
    data: {},
    callback: (data) => {
      
        onSuccess(data.response);
      
    },
    errorCallback: (error) => onError(error),
  });
};

export const forgotpassword = ({ email, onSuccess, onError }) => {
    postRequest({
      endpoint: '/forgotpassword',
      data: {
        email,
      },
      callback: (response) => {
        
          onSuccess();
        
      },
      errorCallback: (error) => onError(error),
    });
  };

  export const register = ({ email, password, onSuccess, onError }) => {
    postRequest({
      endpoint: '/register',
      data: {
        email,
        password,
      },
      callback: (response) => {
        if (response?.access_token === '') {
          onError('Register Unsuccessfull');
        } else {
          Cookies.set('access_token', response.access_token, {
            sameSite: 'none',
            secure: true,
          });
          onSuccess(response.message);
        }
      },
      errorCallback: (error) => onError(error),
    });
  };
  

  export const registerbeta = ({ email, password, onSuccess, onError }) => {
    postRequest({
      endpoint: '/registerbeta',
      data: {
        email,
        password,
      },
      callback: (response) => {
        if (response?.access_token === '') {
          onError('Register Unsuccessfull');
        } else {
          Cookies.set('access_token', response.access_token, {
            sameSite: 'none',
            secure: true,
          });
          onSuccess(response.message);
        }
      },
      errorCallback: (error) => onError(error),
    });
  };
  

  export const deleteProfile = ({ onSuccess }) => {
    postRequest({
      endpoint: '/user/delete',
      data: {},
      callback: () => {
        Cookies.remove('access_token', { path: '/', sameSite: 'none', secure: 'true' });
        onSuccess();
      },
    });
  };

  export const logout = ({ onSuccess }) => {
    postRequest({
      endpoint: '/logout',
      data: {},
      callback: () => {
        console.log("logout")
        Cookies.remove('access_token', { path: '/', sameSite: 'none', secure: 'true' });
        onSuccess();
      },
    });
  };

  export const cancelSubscription = ({ subscriptionId,setReturn }) => {
    postRequest({
      endpoint: '/subscription/cancel',
      data: {subscriptionid:subscriptionId},
      callback: (data) => {
        setReturn(data.response);
      },
    });
  };


  export const getsubscriptionsactive = ({ setReturn }) => {
    postRequest({
      endpoint: '/subscription/active',
      data: {},
      callback: (data) => {
        setReturn(data.response);
      },
    });
  };

  export const getsubscriptionPlans = ({ setReturn }) => {
    postRequest({
      endpoint: '/subscription/plans',
      data: {},
      callback: (data) => {
        setReturn(data.response);
      },
    });
  };

  export const checkLogin = () => {
    if (!Cookies.get('access_token')) {
      return false;
    } else {
      const token = Cookies.get('access_token');
  
      var decodedToken = jwtDecode(token);
  
      var dateNow = new Date();
  
      if (decodedToken.exp * 1000 < dateNow.getTime()) {
        return false;
      }
      return true;
    }
  };
 