// RegistrationPage.js
import React, { useContext } from 'react';
import { Button, Popconfirm } from 'antd';
import LanguageContext from '../LanguageContext';
import { deleteProfile } from '../library/account-helper';
import './css/Register.css';


const SettingsPage = () => {
  const { t } = useContext(LanguageContext);

  const handleDeleteProfile = (values) => {
    //console.log('Success:', values);
    deleteProfile({onSuccess: () => { window.location.reload() }, onError: () => { console.log("error") } });
  };


  return (
    <div className="container">
        <h2>{t.settings.title}</h2>
        <p>{t.settings.description}</p>
        <Popconfirm
    title={t.settings.deleteProfile}
    description={t.settings.deleteProfileDescription}
    okText={t.yes}
    cancelText={t.no}
  >
      <Button style={{color:'red'}} onClick={handleDeleteProfile}>{t.settings.deleteProfile}</Button>
      </Popconfirm>
    </div>
  );
};

export default SettingsPage;