import React, { useEffect, useState, useContext } from 'react';

import { Button, Card, Spin } from 'antd';

import { cancelSubscription } from '../library/account-helper';
import { GlobalContext } from '../GlobalContext';
import './css/Subscribe.css';
import LanguageContext from '../LanguageContext';


const Subscribe = () => {

    const Paddle = window.Paddle;

    
   
    //console.log("Paddle", Paddle)
    const {t} = useContext(LanguageContext);
    const {getGlobalValue, setGlobalValue} = useContext(GlobalContext);

    const lotteryListData = getGlobalValue("lotteryListData");
    const subscriptionPlans = getGlobalValue("subscriptionPlans");
    const subscriptionActive = getGlobalValue("subscriptionsActive");
    const userData = getGlobalValue("userData");
    const [subscriptionCancelled, setSubscriptionCancelled] = useState(null);
    const [showTitle, setShowTitle] = useState(false);
    const [loading, setLoading] = useState(false);

    Paddle.Environment.set("sandbox");
    Paddle.Initialize({
      token: "test_1a94c21d530e173fac171ef3dfd", // replace with a client-side token
      eventCallback: function(data) {
        switch(data.name) {
          case "checkout.loaded":
            console.log("Checkout opened");
            break;
          case "checkout.customer.created":
            console.log("Customer created");
            break;
          case "checkout.completed":
            console.log("Checkout completed");
            //window.location.reload();
            

            setTimeout(() => {
              setGlobalValue("refreshData", true);
              setLoading(false)
            }, 3000);

            break;
          default:
            console.log(data);
        }
      }
    });

  // define items
  let itemsList = [
    {
      priceId: "pri_01hvvgwdh96qyf4czsxa2ma5aa",
      quantity: 1
    }
  ];
  
  // define customer details
  let customerInfo = {
       email: userData?.email,
  }
  
  // open checkout
  function openCheckout(items, type){
    setLoading(true)
    Paddle.Checkout.open({
      items: items,
      customData: {userid:userData?.id, type:type},
      customer: customerInfo,
  
    });
  }

  const handleCancelSubscription = (subscriptionId) => {
    console.log(subscriptionId)
    cancelSubscription({subscriptionId:subscriptionId, setReturn:setSubscriptionCancelled})

  }

useEffect(() => {

  console.log("subscriptionCancelled", subscriptionCancelled)
    if (subscriptionCancelled) {
        setTimeout(() => {
          window.location.reload();
        }, 1000);
    }

}, [subscriptionCancelled]);

return (<>
  <h2>{t.closedbeta}</h2>
  <p>{t.closedbetadesc}</p>
  </>
);

    return (<>
    <div style={{ display: 'flex',alignItems:'center', paddingTop:20, flexDirection:'column', justifyContent:'center'}}> 
     {getGlobalValue("loggedIn") && subscriptionActive && subscriptionActive.length > 0 && <>
    <h3>{t.subscribedto}</h3>
    <div className='lotteryContainer'>
      
              {subscriptionActive.map((lottery, index) => {
                    const title = t.lottery[lottery["type"]];
                    
                    return (
                        <Card className='lotteryCard' title={<span style={{color:lottery.color}}>{title}</span>}  style={{ backgroundColor: lottery.backgroundcolor }}>
                            {lottery.cancelled_at ? (<>
                            <p>{t.cancelled}: {lottery.cancelled_at}</p>
                            <p>{t.expires}: {lottery.next_bill_date}</p>
                            </>):(
                              <p>{t.renews}: {lottery.next_bill_date}</p>
                            )}
                            
                            {!lottery.cancelled_at && <Button className='subscribebutton' onClick={()=>handleCancelSubscription(lottery.subscription_id)} >{t.cancelRenewal}</Button>}
                            
                            
                        </Card>
                    );
                    
                })}
    </div></>
}
    {showTitle &&  <h3>{t.subscribe}</h3>}
    <div className='lotteryContainer'>

               {subscriptionPlans && subscriptionPlans.map((lottery, index) => {
                    const title = t.lottery[lottery.type];
                    
                    let isSubscribed = false
                    if (subscriptionActive && subscriptionActive.length > 0){
                      isSubscribed = subscriptionActive?.some(subscription => subscription["plan_id"] === lottery.paddle_plan_id);
                    }
                    
                    if (!isSubscribed) {
                      if (showTitle === false){
                        setShowTitle(true)
                      }
                      
                    return (
                        <Card className='lotteryCard' title={<span style={{color:lottery.color}}>{title}</span>} style={{ backgroundColor: lottery.backgroundcolor }}>
                            {getGlobalValue("loggedIn") ? (
                            <Button className='subscribebutton' onClick={() => openCheckout([{ priceId: lottery.paddle_plan_id }], lottery.type)}>{t.getfor} {lottery.currency}{lottery.price}/{t[lottery.interval]}</Button>
                            ) : (
                                <Button className='subscribebutton' onClick={() => window.location.href = "/login"}>{t.logingetfor} {lottery.currency}{lottery.price}/{t[lottery.interval]}</Button>
                            )}
                            
                        </Card>
                    );
                    }
                })}
            </div>

    
    
    </div>
    </>)


}

export default Subscribe;