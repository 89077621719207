import React, { useState, useEffect, useContext } from 'react';
import { Steps, Modal, Select, InputNumber, Checkbox, Form, Button, Radio, Input, Segmented, Switch, Tooltip } from 'antd';
import { PlusOutlined, SettingOutlined, DeleteOutlined, PauseCircleOutlined, PlayCircleOutlined, DownloadOutlined, WarningFilled } from '@ant-design/icons';
import {filterList, runSteps, stepsList, stepsLoad, stepsSave, stepDelete} from '../library/data-helper';
import { GlobalContext } from '../GlobalContext';
import './css/Filters.css';
import LanguageContext from '../LanguageContext';
import StrategySimulator from './StrategySimulator';
import NumberPattern from './modules/numberPattern';
import NumberSelect from './modules/numberSelect';
import _ from 'lodash';
//import Draggable from 'react-draggable';
import DecadeSelect from './modules/decadeSelect';
import EndNumberSelect from './modules/endNumberSelect';

const { Option } = Select;





function Simulator() {
  const {t} = useContext(LanguageContext);
  const [steps, setSteps] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [insertPosition, setInsertPosition] = useState(null);
  const [editingStepIndex, setEditingStepIndex] = useState(null);
  const [options, setOptions] = useState([]); // Add more options as needed
  const [stepResult, setStepResult] = useState([]);
  const {getGlobalValue} = useContext(GlobalContext);

  const [isSimulateVisible, setIsSimulateVisible] = useState(false);
  const [maxNumber, setMaxNumber] = useState(0);
  const [selectedStrategy, setSelectedStrategy] = useState(null);
  const [stepSaveList, setSaveStepList] = useState(null);
  const [isModalVisibleSave, setIsModalVisibleSave] = useState(false);
const [newStepName, setNewStepName] = useState('');
const [days, setDays] = useState([]);
const [selectedDay, setSelectedDay] = useState(null);
const [selectedLottery, setSelectedLottery] = useState(null);
const subscriptionActive = getGlobalValue("subscriptionsActive");

let isSubscribed = false
if (subscriptionActive && subscriptionActive.length > 0 && selectedLottery){
  isSubscribed = subscriptionActive?.some(subscription => subscription["type"] === selectedLottery);
}

const handleSaveClick = () => {
 
  setIsModalVisibleSave(true);

};

function StepComponent({ step, stepResult, name, settings = {}, onSettingsClick,removeStep, pauseStep }) {
  let settingsStr = "";
  if (Object.keys(settings).length) {
    const extractNameAndValue = Object.entries(settings).map(([key, setting]) => {
      return `${t[key+"SettingsName"]}: ${setting.value}`;
    });
    settingsStr = extractNameAndValue.join(', ');
  }
  // Display the settings string
  console.log("step",step)
  return (
    <div style={{width:'100%'}}>
      {t[step.id+"Name"]}
      
      {step.id !== "start" && <Button type="link" icon={<SettingOutlined />} onClick={onSettingsClick} /> }
      {step.id !== "start" && step.id !== "system" && <Button type="link" icon={<DeleteOutlined/>} onClick={() => removeStep(step.idq)}/>}
      {step.id !== "start" && step.id !== "system" && (
        <Button
          type="link"
          icon={step.pause ? <PlayCircleOutlined style={{fontSize: 17}}/>: <PauseCircleOutlined style={{fontSize: 17}}/>}
          onClick={() => pauseStep(step.idq)}
          style={{ color: step.pause ? 'blue' : 'grey', }}
        />
      )}
      {step.id !== "start" && (<p style={{fontSize:'9pt', lineHeight:'9pt', whiteSpace:'normal', marginTop: -5}}>{settingsStr}</p>)}
      {stepResult?.steps?.map((result) => {
        if (result.id === step.id && result.idq === step.idq ) {
          return (<>
             {result.result?.length>0 && <p style={{fontSize:'9pt', lineHeight:'9pt', whiteSpace:'normal', marginTop: -5,color: (step.action === 'remove') ? 'red' : 'green'}}>{t.result}: {result.result?.join(", ")} ({result.result?.length})</p>}
            {result.resultbonusnumber?.length>0 && step.id==='system' && <p style={{fontSize:'9pt', lineHeight:'9pt', whiteSpace:'normal', marginTop: -5,color: "black"}}>{t.bonusnumbers}: {result.resultbonusnumber?.join(", ")}</p>}

            {result.resultbonusnumber?.length>0 && step.id!=='system' && <p style={{fontSize:'9pt', lineHeight:'9pt', whiteSpace:'normal', marginTop: -5,color: (step.action === 'remove') ? 'red' : 'green'}}>{t.bonusnumbers}: {result.resultbonusnumber?.join(", ")}</p>}
            </>
          );
        }
        
            
        //return null;
      })}
    </div>
  );
}

function handleSaveOK() {
 
  stepsSave({steps: steps, name: newStepName, lottery:getGlobalValue("selectedLottery"), setReturn:setSaveStepList});
  setIsModalVisibleSave(false);

}

  // Extract days array
  const lotteryListData = getGlobalValue("lotteryListData");


useEffect(() => {

  if (lotteryListData !== null && lotteryListData !== undefined) {
    
    const selectedLottery2 = getGlobalValue("selectedLottery");
   if (lotteryListData.hasOwnProperty(selectedLottery2)) {
    setSelectedLottery(selectedLottery2);
      setDays(lotteryListData[selectedLottery2].days);
 
        setMaxNumber(lotteryListData[selectedLottery2].max);
     
      
   }
  }

}, [lotteryListData, getGlobalValue("selectedLottery")]);

useEffect(() => {
  if (days.length === 0) return;
  setSelectedDay(days[0]?.value);
}, [days]);

// State variable for selected day


  const handleSimulateClick = () => {
    setIsSimulateVisible(!isSimulateVisible);
  };


 

  const addStep = () => {
    let settingsObject = {};
    if (selectedOption.settings) {
      Object.entries(selectedOption.settings).forEach(([key, setting]) => {
        settingsObject[key] = {value:setting.value, values:setting.values, name:setting.name, type:setting.type, limit:setting.limit};
      });
    }
  
    const uniqueId = Date.now();

  if (editingStepIndex !== null) {
    setSteps(prevSteps => prevSteps.map((step, index) => index === editingStepIndex ? {...selectedOption, settings: settingsObject, idq: uniqueId} : step));
  } else {
    setSteps(prevSteps => [
      ...prevSteps.slice(0, insertPosition + 1),
      {...selectedOption, settings: settingsObject, idq: uniqueId},
      ...prevSteps.slice(insertPosition + 1)
    ]);
  }
    //setOptions(prevOptions => prevOptions.filter(option => option.name !== selectedOption.name));
    setSelectedOption(null);
    setIsModalVisible(false);
  };

    const removeStep = (idq) => {
    setSteps(steps.filter(step => step.idq !== idq));
  };

  const pauseStep = (idq) => {
    setSteps(steps.map((step, index) => step.idq === idq ? {...step, pause: step.pause === true ? false : true} : step));
  }

  const openModal = (index, isEditing = false) => {
    setInsertPosition(index);
    setSelectedOption(isEditing ? steps[index] : null);
    setEditingStepIndex(isEditing ? index : null);
    setIsModalVisible(true);
  };

  const handleInputChange = (id, value) => {
    setSelectedOption(prevOption => {
      const newSettings = Object.fromEntries(
        Object.entries(prevOption.settings).map(([key, setting]) => {
          if (key === id) {
            return [key, { ...setting, value }];
          }
          return [key, setting];
        })
      );
      return { ...prevOption, settings: newSettings };
    });
  };



  const handleInputChangeSelected = (value) => {
    console.log(value);
    let val= value ? 'add' : 'remove';

    setSelectedOption(prevOption => {
      return { ...prevOption, action: val };
    });
  };




    useEffect(() => {
    stepsList({setReturn:setSaveStepList})

    const storedSteps = localStorage.getItem('laststepsstrategy');
    if (storedSteps && storedSteps.length > 0) {
      setSteps(JSON.parse(localStorage.getItem('laststepsstrategy')));
    } else {
    setSteps([{ name: t.systemnumbers, id:"system", hideaction:true, description:"Maximum amount of system numbers, based on how often each number shows up in all filters", settings:{limit:{value:30,name:" Limit",type:"number",limit:30}} }]);
    }
  }, []);

  const resetSteps = () => {
    setSteps([{ name: t.systemnumbers, id:"system", hideaction:true, description:"Maximum amount of system numbers, based on how often each number shows up in all filters", settings:{limit:{value:30,name:" Limit",type:"number",limit:30}} }]);

  }

  useEffect(() => {
    if (options.length) return;

    filterList({ type: 'filter', setReturn: setOptions});


  }, [options]);

  useEffect(() => {
    //const stepsJson = JSON.stringify(steps);
    //console.log(stepsJson);
    // Do whatever you need with stepsJson here
    console.log("selectedLottery",selectedLottery)
    if (steps.length < 2 || selectedDay == undefined || selectedLottery == undefined) return;
    runSteps({ steps, lottery:selectedLottery, day: selectedDay, setReturn: setStepResult});

    localStorage.setItem('laststepsstrategy', JSON.stringify(steps));

  }, [steps, selectedDay, selectedLottery]);

  useEffect(() => {
 
    if (stepSaveList?.length===0 && steps.length<2) 
    {
  
      openModal(-1);
    }
  }, [stepSaveList, steps]);

const handleStrategyDelete = (e) => {
  stepDelete({stepid: e, setReturn: setSaveStepList})
}



  return (<>

  <div style={{ display: 'flex', height: '100vh', width: '99vw', paddingTop:0}}>
  <div style={{ flex:editingStepIndex !== null ? 2 : 1, overflowY:'auto', height:'auto' }}>

  <h2>{t.strategy}</h2>
  <p>{t.strategysubtitle}</p>
    <div className='filters'>

    

      {getGlobalValue("loggedIn") && stepSaveList && steps.length>1 &&
   <>
      <div className='filters__option'>
        <label>{t.loadstrategy}</label>
      <Select 
  style={{ width: 200, zIndex: 5000}}
  placeholder={t.selectStrat}
  onChange={(value) => {setStepResult([]); stepsLoad({stepid: value, setReturn: setSteps});setSelectedStrategy(value);}}
 
>
  {stepSaveList?.map((step, index) => (
    <Option key={step.id} value={step.id} >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {step.name}
        {selectedStrategy !== step.id && (
          <Button className="deleteButton"
            type="danger" 
            size="small" 
            icon={<DeleteOutlined/>} 
            style={{ marginLeft: '10px' }} 
            onClick={(e) => {
              e.stopPropagation(); // prevent the select dropdown from closing
              handleStrategyDelete(step.id);
            }}
          />
        )}
      </div>
    </Option>
  ))}
</Select>
</div>    
<div className='filters__option'>
<Button onClick={handleSaveClick} icon={<DownloadOutlined/>}></Button>
</div>


<Modal
  title={t.savestrategy}
  open={isModalVisibleSave}
  onOk={() => {handleSaveOK();}}
  
  onCancel={() => setIsModalVisibleSave(false)}
>
  <Input onChange={(e) => setNewStepName(e.target.value)} />
</Modal>


      
      

  
  </>
  
      }
      </div>
      
   <div className='filters'>   
      <div className='filters__option'>
      <label>{t.dayfilter}</label>
      <Radio.Group onChange={e => setSelectedDay(e.target.value)} defaultValue={selectedDay} value={selectedDay}>
        {days.map((day, index) => (
          <Radio.Button key={index} value={day.value}>{t[day.day]}</Radio.Button>
        ))}
      </Radio.Group>
      </div>
    </div>
    <div>

      <h3>{t.filters}<span><Button style={{borderStyle:'none'}} onClick={resetSteps}>[{t.reset}]</Button></span></h3> 

      {stepResult?.lastdate && <p style={{fontSize:'10pt'}}>Checked from last draw {stepResult?.lastdate[0]?.date}</p>}
      {!isSubscribed && <><p style={{fontSize:'10pt'}}><WarningFilled style={{color:'red', fontSize:22}}/> {t.nosubscription}</p></>}
      <Tooltip color='green' placement='right' open={steps.length<=2 && !isModalVisible} title={t.addfirststep} defaultOpen overlayStyle={{zIndex:1}}><Button onClick={() => openModal(-1)} style={{borderStyle:'none', padding:0}}><PlusOutlined style={{fontSize:30}}/></Button></Tooltip>
      <div style={{paddingTop:20}}>
        {steps.length>1 &&
      <Steps direction="vertical" id="steps" style={{display:'inline'}}>
        {steps.map((step, index) => (
          <React.Fragment key={index}>
           
            <Steps.Step title={<StepComponent step={step} stepResult={stepResult} name={step.name} settings={step.settings} removeStep={removeStep} pauseStep={pauseStep} onSettingsClick={() => openModal(index, true)} />} icon={<span style={{ width: '32px', height: '32px', borderRadius: '0', clipPath:'polygon( 0% 0%, calc(100% - 0.4em) 0%, 100% 50%, calc(100% - 0.4em) 100%, 0% 100%)', backgroundColor: (step.action === 'remove') ? '#ff0000a8' : step.id==='system' ? '#5789ff' :step.group==='bonusnumbers' ? 'orange':'#3ed75da8', display: 'inline-block' }} />}/>
            {index < steps.length-1 && (
              
              <Steps.Step icon={<PlusOutlined />} onClick={() => openModal(index)} title="" />
            )}
          </React.Fragment>
        ))}
      </Steps>
} 
      </div>
      <Modal className={editingStepIndex !== null && isSimulateVisible ? "" : ""} title={(editingStepIndex !== null || editingStepIndex !== undefined) && steps[editingStepIndex] ? `${t.edit} - ${steps[editingStepIndex].name}` : t.addfilter} open={isModalVisible} onOk={addStep} destroyOnClose onCancel={() => setIsModalVisible(false)}>
  <Form>
    {editingStepIndex === null && (
      <Form.Item name="option" label="Name">
        <Select className="filterSelect" placeholder={t.selectfilter} onChange={value => setSelectedOption(options.find(option => option.id === value))} style={{ width: '100%' }}>
      {Object.entries(_.groupBy(options, 'group')).map(([group, options]) => (
        <Select.OptGroup key={group} label={t[group]}>
          {options.sort((a, b) => a.name.localeCompare(b.name)).map(option => {
            
            let name = t[option.id+"Name"];
            let desc = t[option.id+"Desc"];

            if (name === undefined) name = option.name;
            if (desc === undefined) desc = option.description;

          
            return (
            <Select.Option key={option.id} value={option.id} label={name}>
              <div>
                <div>{name}</div>
                <small className='selectdescription'>{desc}</small>
              </div>
            </Select.Option>
          )
      })}
        </Select.OptGroup>
      ))}
    </Select>
    </Form.Item>
    )}
    {selectedOption && <p>{!selectedOption.hideaction && <Switch className="switchFilter" onChange={handleInputChangeSelected} checkedChildren={t.add} unCheckedChildren={t.remove} checked={selectedOption.action == 'add' ? true : false} />} {t[selectedOption.id+"Desc"]}</p>}

    {selectedOption && Object.entries(selectedOption.settings).map(([key, setting]) => (
     <> 
      <Form.Item key={key} name={key} label={t[key+"SettingsName"]}>
  
      {setting.type === 'number' ? (
    <InputNumber min={1} max={setting.limit} defaultValue={setting.value} onChange={value => handleInputChange(key, value)} />
  ) : setting.type === 'text' ? (
    <Input type="text" defaultValue={setting.value} onChange={e => handleInputChange(key, e.target.value)} />
  ) : setting.type === 'segment' ? (
    <Segmented options={setting.values} defaultValue={setting.value} value={setting.value} onChange={value => handleInputChange(key, value)} />
    
    ) : setting.type === 'pattern' ? (  
      
      <NumberPattern pattern={setting.value} onChange={value => handleInputChange(key, value)}/>

     ) : setting.type === 'decadeNumber' ? (  
      
      <DecadeSelect numbers={setting.value} maxNumber={maxNumber} onChange={value => handleInputChange(key, value)}/>
  )
  : setting.type === 'endNumber' ? (  
      
      <EndNumberSelect numbers={setting.value} maxNumber={maxNumber} onChange={value => handleInputChange(key, value)}/>
  )

   : setting.type === 'numbersform' ? (  
      
      <NumberSelect numbers={setting.value} maxNumber={maxNumber} numRows={lotteryListData[selectedLottery].rows}  numCols={lotteryListData[selectedLottery].cols} onChange={value => handleInputChange(key, value)}/>
  ) : (
    <Checkbox checked={setting.value} onChange={e => handleInputChange(key, e.target.checked)} />
  )}
</Form.Item>
</>
))}

  </Form>
</Modal>
</div>
</div>
{isSimulateVisible ? (
      
      <StrategySimulator isSimulateVisible={isSimulateVisible} setIsSimulateVisible={setIsSimulateVisible} steps={steps} selectedDay={selectedDay} t={t} maxNumber={maxNumber}></StrategySimulator>

):( steps.length>1 && < div style={{flex: 2, justifyContent:'center', marginTop:'200px', textAlign:'center'}}>
  <PlayCircleOutlined style={{color:'#70d176', fontSize:150}} onClick={handleSimulateClick}></PlayCircleOutlined>
  <p style={{color:'#70d176', fontSize:30}}>{t.simulatestrategy}</p>
</div>)}
    </div>
    
    </>
  );
}

export default Simulator;