import React, { useContext, useEffect, useState }  from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { DatePicker, Radio, Tabs } from 'antd';
import LanguageContext from '../LanguageContext';
import { GlobalContext } from '../GlobalContext';
import './css/Statistics.css';
import dayjs from "dayjs";
import {lotteryDraws} from '../library/data-helper';

import EvolutionGraph from './modules/evolutionGraph';
import BarChartComponent from './modules/hitBarChart';
import HeatMap from './modules/heatmap';

const defaultDates = [
  dayjs("2023-01-01"), // start date
  dayjs(), // end date
];

const rangePresets = [
  
  {
    label: 'This Year',
    value: [dayjs().startOf('year'), dayjs()],
  },
  {
    label: 'Last 365 days',
    value: [dayjs().add(-365, 'd'), dayjs()],
  },
  {
    label: 'Last 2 Years',
    value: [dayjs().add(-365*2, 'd'), dayjs()],
  },
];


function Statistics() {

    const { t } = useContext(LanguageContext);
    const { getGlobalValue } = useContext(GlobalContext);
    const [dates, setDates] = useState([]);
    const [draws, setDraws] = useState({});
    const [dataSource, setDataSource] = useState([]);
    const [loading, setLoading] = useState(false);
    const [lotteryListDataDetails, setLotteryListDataDetails] = useState(null);
    const [defaultTab, setDefaultTab] = useState('heatmap');

    const handleDateRangeChange = (date, dateString) => {
      //setDates(prevDates => ({ ...prevDates, [type]: dateString }));
      setDates(dateString);
    };
    const lotteryListData = getGlobalValue("lotteryListData");
    var days = [];
    if (lotteryListData === null || lotteryListData === undefined) {
      days = [];
    }else{
      const selectedLottery = getGlobalValue("selectedLottery");
      if (lotteryListData.hasOwnProperty(selectedLottery)) {
          days = lotteryListData[selectedLottery].days;
          //;
      }
    }
    const [selectedDay, setSelectedDay] = useState(days[0]?.value);

    const navigate = useNavigate();
    const params = useParams();
    console.log(params); // Should log { lang: 'value' }
    const { tool, lang } = params;

    useEffect(() => {
      console.log("tool", tool)
      if (tool && tool !== "") {
        setDefaultTab(tool);
      }

    }, [tool]);

    useEffect(() => {
        
      setDates([
            defaultDates[0].format("YYYY-MM-DD"),
            defaultDates[1].format("YYYY-MM-DD"),
          ]);
      
   
        }, []);


      useEffect(() => {

        if (!draws || !draws.response) return;

        let counts = {};
        for (let date in draws.response) {
          draws.response[date].forEach(num => {
              if (!counts[num]) {
                  counts[num] = { id: num.toString(), data: [] };
              }
              let countForDate = counts[num].data.find(d => d.x === date);
              if (!countForDate) {
                  counts[num].data.push({ x: date, y: 1 });
              }
          });
      }
  
      // Iterate over counts to calculate cumulative counts
      for (let num in counts) {
          counts[num].data.sort((a, b) => new Date(a.x) - new Date(b.x));
          for (let i = 1; i < counts[num].data.length; i++) {
              counts[num].data[i].y += counts[num].data[i - 1].y;
          }
      }
        //console.log(Object.values(counts))
        setDataSource(Object.values(counts));

        
      }  , [draws]);

        

        useEffect(() => {
          if (!getGlobalValue("selectedLottery")) return;
          
          if (!dates || dates.length !== 2) {
            return;
          }
      
          lotteryDraws({selectedDay:selectedDay, lottery: getGlobalValue("selectedLottery"), startDate: dates[0], endDate: dates[1], day: selectedDay, setReturn: setDraws, setLoading: setLoading});
        }, [dates, selectedDay, getGlobalValue("selectedLottery")]);


        useEffect(() => {
          if (!getGlobalValue("lotteryListData") || !getGlobalValue("selectedLottery")) return;
          setLotteryListDataDetails(lotteryListData[getGlobalValue("selectedLottery")])
        }, [getGlobalValue("lotteryListData"), getGlobalValue("selectedLottery")]);

        const items = [
          {
            key: 'numberevolutiongraph',
            label: t.numberevolutiongraph,
            children: <> 
            <p>{t.numberevolutiongraphdesc}</p>
              <EvolutionGraph dataSource={dataSource} /></>,
          },
          {
            key: 'hitfrequency',
            label: t.hitfrequency,
            children: 
            <><p>{t.hitfrequencydesc}</p>
            <BarChartComponent draws={draws.response} lotteryListDataDetails={lotteryListDataDetails}/>
            </>,
          },
          {
            key: 'heatmap',
            label: t.heatmap,
            children: 
            <><p>{t.heatmapdesc}</p>
            <HeatMap draws={draws.response} lotteryListDataDetails={lotteryListDataDetails}/>
            </>,
          }
        ]



const handleChange = (activeKey) => {
  setDefaultTab(activeKey);
  console.log("langcheck", lang);
  if (lang) {
    navigate(`/${lang}/statistics/${activeKey}`);
  } else {
    navigate(`/statistics/${activeKey}`);
  }
};

return (
  <div style={{ display: 'flex',alignItems:'center', paddingTop:20, flexDirection:'column', justifyContent:'center'}}> 
    <div className='filters'>
      <div className='filters__option'>
        <label>{t.datefilter}</label>
        <DatePicker.RangePicker
          showWeek
          defaultDates={defaultDates}
          presets={rangePresets}
          value={dates.map(date => dayjs(date))}
          onCalendarChange={handleDateRangeChange}
          allowClear={false}
        />
      </div>
      <div className='filters__option'>
        <label>{t.dayfilter}</label>
        <Radio.Group onChange={e => setSelectedDay(e.target.value)} defaultValue={selectedDay} value={selectedDay}>
          {days?.map((day, index) => (
            <Radio.Button key={index} value={day.value}>{t[day.day]}</Radio.Button>
          ))}
        </Radio.Group>
      </div>
    </div>
    <Tabs centered activeKey={defaultTab} onChange={handleChange} defaultActiveKey={defaultTab} items={items}/>

    </div>

);



}

export default Statistics;