import React, { useContext, useEffect, useState }  from 'react';
import { forgotpassword } from '../library/account-helper';
import { Form, Input, Button } from 'antd';
import LanguageContext from '../LanguageContext';
import { GlobalContext } from '../GlobalContext';
import './css/Register.css';

function ForgotPassword() {

    const { t } = useContext(LanguageContext);
    const [email, setEmail] = React.useState("");
    const { setGlobalValue, getGlobalValue } = useContext(GlobalContext);
    const [errorMessage, setErrorMessage] = useState(false);
    const [submitted, setSubmitted] = useState(false);

useEffect(() => {   
    if (getGlobalValue("loggedIn")) {
        window.location.href = "/";
    }

}, [getGlobalValue("loggedIn")]);


return (
 <div className='container'> 

  {submitted ? (<p style={{color:'green'}}>{t.emailsentifexists}</p>) :
 <Form className='login-form'
    onFinish={() => {

      forgotpassword({ email, onSuccess: () => { setSubmitted(true) }, onError: (error) => { setErrorMessage(true); } });
      
    }}
 >
    <Form.Item>
      <Input placeholder="Email" onChange={e => setEmail(e.target.value)} />
    </Form.Item>
    <Form.Item>
      <Button type="primary" htmlType="submit">Reset</Button>
    </Form.Item>
    
    {errorMessage && <p style={{color:'red'}}>Error Login</p>}

 </Form>
}
 </div>

  );



}

export default ForgotPassword;