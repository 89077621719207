import React, { useEffect, useState, useContext } from 'react';
import moment from 'moment';
import dayjs from "dayjs";
import { Button, DatePicker, Table, Tabs, Spin } from 'antd';
import { WarningFilled } from '@ant-design/icons';
import {simulateSteps} from '../library/data-helper';
import { groupBy, map } from 'lodash';
import { GlobalContext } from '../GlobalContext';
import StrategySimulatorSystemCheck from './StrategySimulatorSystemCheck';
let defaultDates = [
    dayjs("2022-01-01"), // start date
    dayjs(), // end date
  ];


  const rangePresets = [
  
    {
      label: 'This Year',
      value: [dayjs().startOf('year'), dayjs()],
    },
    {
      label: 'Last 365 days',
      value: [dayjs().add(-365, 'd'), dayjs()],
    },
    {
      label: 'Last 2 Years',
      value: [dayjs().add(-365*2, 'd'), dayjs()],
    },
  ];

const StrategySimulator = ({isSimulateVisible, setIsSimulateVisible, steps, selectedDay, t, maxNumber}) => {

    const [dates, setDates] = useState([]);
    const [loadingSim, setLoadingSim] = useState(false);
    const [stepSimulateResult, setStepSimulateResult] = useState([]);
    const {getGlobalValue} = useContext(GlobalContext);
    const lotteryListData = getGlobalValue("lotteryListData");
    const [viewType, setViewType] = useState('Detail');
    const numbers = Array.from({ length: maxNumber }, (_, i) => i + 1);
    const handleDateRangeChange = (date, dateString) => {
        //setDates(prevDates => ({ ...prevDates, [type]: dateString }));
        setDates(dateString);
      };
    
    const scoring = lotteryListData[getGlobalValue("selectedLottery")].scoring;
    const subscriptionActive = getGlobalValue("subscriptionsActive");
    let isSubscribed = false
    if (subscriptionActive && subscriptionActive.length > 0 && getGlobalValue("selectedLottery")){
      isSubscribed = subscriptionActive?.some(subscription => subscription["type"] === getGlobalValue("selectedLottery"));
    
      
    
    }

    if (!isSubscribed) {

      defaultDates = [
        dayjs("2019-01-01"), // start date
        dayjs("2020-12-31"), // end date
      ];

    }else{
      defaultDates = [
        dayjs("2022-01-01"), // start date
        dayjs(), // end date
      ];
    }

  const columnPattern = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      className: 'first-column',
      width: 30,
    },
    ...numbers.map((numbers, index) => ({
      title: String(numbers),
      dataIndex: String(numbers),
      key: String(numbers),
      width:10,
      className: index % 2 === 0 ? 'light-column' : 'normal-column', // Add the CSS class to every second column
      render: (number, record) => {

        const isHighlighted = record.suggestion?.includes(numbers);
        const isDrawn = record.numbers.includes(numbers);
        return {
          props: {
            style: { backgroundColor: isHighlighted ? (isDrawn ? 'lightgreen' : 'lightblue') : 'inherit' },
          },
          children: <div>{isDrawn ? numbers: ''}</div>,
        };
      },
    })),
  ];

  const columnDetails = [
    {
      title: t.date,
      dataIndex: 'date',
      key: 'date',
      sorter: (a, b) => a.date.localeCompare(b.date),
    },
    {
      title: t.matches,
      dataIndex: 'matches',
      key: 'matches',
      render: matches => {
        if (matches==="0") return null;
        const items = matches.split('');
        return <strong>{items[1] !== '0' ? items[0]+"+"+items[1] : items[0]}</strong>;
      },
      sorter: (a, b) => a.matches - b.matches,
    },
    {
      title: t.numbers,
      dataIndex: 'numbers',
      key: 'numbers',
      render: (numbers, record) => {
      const numbersSet = new Set(record.suggestion);
      return numbers.map((number, i) => 
        <React.Fragment key={i}>
              {numbersSet.has(number) 
                ? <span style={{backgroundColor: 'yellow'}}>{number}</span> 
                : <span>{number}</span>}
              {i < numbers.length - 1 && ','}
            </React.Fragment>
      );
    },
    },
    {
    title: t.suggestions,
    dataIndex: 'suggestion',
    key: 'suggestion',
    render: (suggestion, record) => {
      const numbersSet = new Set(record.numbers);
      return (
        <>
          {suggestion.map((number, i) => 
            <React.Fragment key={i}>
              {numbersSet.has(number) 
                ? <span style={{backgroundColor: 'yellow'}}>{number}</span> 
                : <span>{number}</span>}
              {i < suggestion.length - 1 && ','}
            </React.Fragment>
          )}
          {' (' + suggestion.length + ')'}
        </>
      );
    },
  },
  ];



  
        useEffect(() => {
        
    setDates([
          defaultDates[0].format("YYYY-MM-DD"),
          defaultDates[1].format("YYYY-MM-DD"),
        ]);
    
 
      }, []);


    //const filteredStepSimulateResult = stepSimulateResult.filter(item => item.matches > 1);
    //const groupedMatches = groupBy(filteredStepSimulateResult, 'matches');
    //const matchCountData = map(groupedMatches, (value, key) => ({ matches: key, count: value.length }));
    
    const stepSimulateResultWithYear = stepSimulateResult.map(item => ({
      ...item,
      year: moment(item.date).year(), // Extract the year from the date
    }));
    
    const groupedByYear = groupBy(stepSimulateResultWithYear, 'year');
    
    const matchCountDataByYear = map(groupedByYear, (value, key) => {
      const filteredValue = value.filter(item => item.matches > 1);
      const data = map(groupBy(filteredValue, 'matches'), (v, k) => ({ 
    matches: k, 
    count: v.length,
    scoring: v.length * scoring[k] || 0 // Calculate the scoring based on the match count
  }));

  return {
    year: key,
    data: data,
    totalscore: data.reduce((total, item) => total + item.scoring, 0), // Calculate the total score
  };
    });
    
    const suggestionsLength = stepSimulateResult.map(item => item.suggestion.length);
    const suggestionsLengthUnique = [...new Set(suggestionsLength)];

    useEffect(() => {
        //const stepsJson = JSON.stringify(steps);
        //console.log(selectedDay);
        if (!dates || dates.length !== 2) {
          return;
        }
        // Do whatever you need with stepsJson here
        if (!isSimulateVisible) return;
        if (steps.length < 2) return;
    
        simulateSteps({ steps, lottery:getGlobalValue("selectedLottery"), setReturn: setStepSimulateResult, startDate: dates[0], endDate: dates[1], day: selectedDay, setLoading: setLoadingSim});
    
      }, [dates, steps, selectedDay, isSimulateVisible, getGlobalValue("selectedLottery")]);

    console.log(matchCountDataByYear);
    const matchCountColumns = [
      {
        title: t.matches,
        dataIndex: 'matches',
        key: 'matches',
        align: 'right',
        render: matches => {
          const items = matches.split('');
          return <strong>{items[1] !== '0' ? items[0]+"+"+items[1] : items[0]}</strong>;
        },
      },
      {
        title: t.count,
        dataIndex: 'count',
        key: 'count',
      },
    ];
      
    
    const tabItems = [
      {
      key: '1',
      label: t.summarybyyear,
      children: (
        <>
        {/*<p>Average Score: {(matchCountDataByYear.reduce((total, yearData) => total + yearData.totalscore, 0) / matchCountDataByYear.length).toFixed(0)}</p> (Score ${yearData.totalscore})*/}
        <div style={{ display: 'flex', overflowX: 'auto' }}>
          {matchCountDataByYear.map((yearData, index) => (
          <>
            <Table
            key={index}
            className="compact-table no-borders"
            columns={matchCountColumns}
            dataSource={yearData.data}
            pagination={false}
            title={() => <b>{yearData.year}</b>}
            style={{ flex: '0 0 auto', marginLeft: index > 0 ? '20px' : '0' }}
            />
          </>
          ))}
        </div>

        <h3 className='title'>{t.result}</h3>
        {/*
        <Segmented onChange={setViewType} value={viewType}
    options={[
      {
        value: 'Detail',
        icon: <BarsOutlined />,
      },
      {
        value: 'Patterns',
        icon: <AppstoreOutlined />,
      },
    ]}
  />
  */}
        <div style={{overflow:'auto', height:'auto'}}>
        <Table className="compact-table no-data-icon" locale={{ emptyText: '\u00A0' }} columns={viewType==='Detail'? columnDetails : columnPattern} pagination={viewType==='Detail'? true : false} dataSource={stepSimulateResult} sticky={viewType==='Detail'? true : true} scroll={{ x: 'max-content' }} />
        </div>
        </>
      ),
      },
      {
      key: '2',
      label: t.systemcheck,
      disabled: false,
      children: <>{getGlobalValue("loggedIn") ? (<StrategySimulatorSystemCheck steps={steps} dates={dates} selectedDay={selectedDay} t={t} suggestionsLengthUnique={suggestionsLengthUnique} />):(<p><a href="/login">{t.login}</a> {t.or} <a href="/register">{t.register}</a> {t.needtosignin}</p>)}</>,
      },
    ];


    return (
        <div style={{ flex: 3 }} className={`simulate-section ${isSimulateVisible ? 'visible' : ''}`}>
          <h3>{t.simulator}</h3>
              <Button className="close-button" onClick={() => setIsSimulateVisible(false)}>X</Button>
              <div className='filters'>
  <div className='filters__option'>
     <label>{t.datefilter}</label>
  <DatePicker.RangePicker
  showWeek
  presets={rangePresets}
  defaultDates={defaultDates}
  value={dates.map(date => dayjs(date))}
  onCalendarChange={handleDateRangeChange}
  allowClear={false}
/>
{!isSubscribed && <><p style={{fontSize:'10pt'}}><WarningFilled style={{color:'red', fontSize:22}}/> {t.nosubscription}</p></>}

</div>
</div>
{loadingSim ? <Spin /> : (<>
<Tabs defaultActiveKey="1" items={tabItems} />
 
  </>)
  }


        </div>
    )
   
}

export default StrategySimulator;