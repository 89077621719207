
import { postRequest } from './middleware';


export const filterList = ({ type, setReturn}) => {
    postRequest({
      endpoint: '/filter/list',
      data: {
        type,        
      },
      callback: (data) => {
          
          setReturn(data.response);
        
      },
      errorCallback: (error) => console.log(error),
    });
  };

  export const lotteryList = ({ setReturn}) => {



    postRequest({
      endpoint: '/lottery/list',
      data: {               
      },
      callback: (data) => {
           setReturn(data.response)
        
      },
      errorCallback: (error) => console.log(error),
    });
  };
  
  export const stepsList = ({ setReturn}) => {
    postRequest({
      endpoint: '/steps/list',
      data: {               
      },
      callback: (data) => {
           setReturn(data.response)
        
      },
      errorCallback: (error) => console.log(error),
    });
  }

  export const stepsSave = ({steps, name, lottery, setReturn}) => {
    postRequest({
      endpoint: '/steps/save',
      data: {  steps, name, lottery             
      },
      callback: (data) => {
           setReturn(data.response)
        
      },
      errorCallback: (error) => console.log(error),
    });
  }

  export const stepDelete = ({stepid, setReturn}) => {
    postRequest({
      endpoint: '/steps/delete',
      data: {  stepid             
      },
      callback: (data) => {
           setReturn(data.response)
        
      },
      errorCallback: (error) => console.log(error),
    });
  }

  export const stepsLoad = ({stepid, setReturn}) => {
    postRequest({
      endpoint: '/steps/load',
      data: {  stepid             
      },
      callback: (data) => {
           setReturn(data.response)
        
      },
      errorCallback: (error) => console.log(error),
    });
  }

  export const runSteps = ({ steps, lottery, endDate="", day=-1, setReturn}) => {
    postRequest({
      endpoint: '/steps/run',
      data: {
        steps,
        lottery,
        endDate, 
        day      
      },
      callback: (data) => {
          console.log(data)
          setReturn(data.response);
        
      },
      errorCallback: (error) => console.log(error),
    });
  };

  export const simulateSteps = ({ steps, lottery, startDate="2023-01-01", endDate="2024-12-31", day=-1, setReturn, setLoading}) => {
    setLoading(true);
    postRequest({
      endpoint: '/steps/simulate',
      data: {
        steps,
        lottery,
        endDate,
        startDate,
        day      
      },
      callback: (data) => {
          console.log(data)
          setReturn(data.response);
          setLoading(false);
        
      },
      errorCallback: (error) => {
        setLoading(false);
        console.log(error)
      },
    });
  };

  export const simulateStepsSystem = ({ steps, lottery, startDate="2023-01-01", endDate="2024-12-31", day=-1, setReturn, setLoading, systems}) => {
    setLoading(true);
    postRequest({
      endpoint: '/steps/simulatesystem',
      data: {
        steps,
        lottery,
        endDate,
        startDate,
        day,
        systems      
      },
      callback: (data) => {
          console.log(data)
          setReturn(data.response);
          setLoading(false);
        
      },
      errorCallback: (error) => {
        setLoading(false);
        console.log(error)
      },
    });
  };

  export const lotteryDrawsPatterns = ({ steps, lottery, startDate="2023-01-01", endDate="2024-12-31", day=-1, setReturn, setLoading}) => {
    setLoading(true);
    postRequest({
      endpoint: '/lottery/drawpatterns',
      data: {
        steps,
        lottery,
        endDate,
        startDate,
        day      
      },
      callback: (data) => {
          console.log(data)
          setReturn(data);
          setLoading(false);
        
      },
      errorCallback: (error) => {
        setLoading(false);
        setReturn(null);
        console.log(error)
      },
    });
  };

  

  export const lotteryStatsOften = ({ lottery, startDate="2023-01-01", endDate="2024-12-31", day=-1, setReturn, setLoading}) => {
    setLoading(true);
    postRequest({
      endpoint: '/lottery/stats/often',
      data: {
        lottery,
        endDate,
        startDate,
        day      
      },
      callback: (data) => {
          console.log(data)
          setReturn(data);
          setLoading(false);
        
      },
      errorCallback: (error) => {
        setLoading(false);
        console.log(error)
      },
    });
  };

  export const lotteryDraws = ({ lottery, startDate="2023-01-01", endDate="2024-12-31", day=-1, setReturn, setLoading}) => {
    setLoading(true);
    postRequest({
      endpoint: '/lottery/draws',
      data: {
        lottery,
        endDate,
        startDate,
        day      
      },
      callback: (data) => {
          console.log(data)
          setReturn(data);
          setLoading(false);
        
      },
      errorCallback: (error) => {
        setLoading(false);
        console.log(error)
      },
    });
  };

  export const lotterySystemsList = ({ lottery, setReturn, setLoading}) => {
    setLoading(true);
    postRequest({
      endpoint: '/lottery/systems/list',
      data: {
        lottery,    
      },
      callback: (data) => {
          
          setReturn(data.response);
          setLoading(false);
        
      },
      errorCallback: (error) => {
        setLoading(false);
        setReturn(null);
        console.log(error)
      },
    });
  };

  export const getlotterySystem = ({ lottery, systemid, setReturn}) => {
  
    postRequest({
      endpoint: '/lottery/systems/get',
      data: {
        systemid, 
        lottery,   
      },
      callback: (data) => {
          
          setReturn(data.response);
       
        
      },
      errorCallback: (error) => {
     
        setReturn(null);
        console.log(error)
      },
    });
  };

  export const lotterySystemSave = ({ system, numbersinrow, systemnumbers, rows, systemname, makepublic, id, setReturn}) => {
    
    postRequest({
      endpoint: '/lottery/systems/save',
      data: {
        system, numbersinrow, systemnumbers, rows, systemname, makepublic, id    
      },
      callback: (data) => {
          
        setReturn(data.response);
        
      },
      errorCallback: (error) => {
        return false;
      },
    });
  };

   export const lotterySystemCheck = ({ system, setReturn, setLoading}) => {
    setLoading(true)
    postRequest({
      endpoint: '/lottery/systems/check',
      data: {
        system    
      },
      callback: (data) => {
        setLoading(false)
        setReturn(data.response);
        
      },
      errorCallback: (error) => {
        setLoading(false)
        return false;
      },
    });
  };