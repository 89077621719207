import React, { useContext } from 'react';
import LanguageContext from '../LanguageContext';
import './css/Register.css';


const Imprint = () => {

    const { t} = useContext(LanguageContext);

    return (

        t.legal.imprint
    )

}

export default Imprint;