import React, { useEffect, useState, useContext } from 'react';

import { Table, Input, Button, Checkbox, Tabs, Col, Row, Select, Spin } from 'antd';
import {lotterySystemsList,lotterySystemSave, getlotterySystem, lotterySystemCheck} from '../library/data-helper';

import { GlobalContext } from '../GlobalContext';
import './css/SystemBuilder.css';
import LanguageContext from '../LanguageContext';

const { TextArea } = Input;
  

const SystemBuilder = () => {
    const {t} = useContext(LanguageContext);
    const {getGlobalValue} = useContext(GlobalContext);
    const [loadingSim, setLoadingSim] = useState(false);
    const [lotterySystems, setLotterySystems] = useState([]);
    const [systemData, setSystemData] = useState([]);
    const selectedLottery = getGlobalValue("selectedLottery");
    const [systemName, setSystemName] = useState('');
    const [systemMaxNumber, setSystemMaxNumber] = useState(0);
    const [systemTotalRows, setSystemTotalRows] = useState(0);
    const [makepublic, setMakePublic] = useState(false);
    const [systemId, setSystemId] = useState(0);
    const [systemResult, setSystemResult] = useState(null);
    const [systemResultLoading, setSystemResultLoading] = useState(null);
    const [checkData, setCheckData] = useState([]);
    const [checkColumns, setCheckColumns] = useState([]);
    const [selectedSystem, setSelectedSystem] = useState(null);
    const [garanteedNumbers, setGaranteedNumbers] = useState([]);
    const lotteryListData = getGlobalValue("lotteryListData");
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedTab, setSelectedTab] = useState('1');

    useEffect(() => {
        if (getGlobalValue("loggedIn") && selectedLottery !== null && selectedLottery !== undefined ) {

        lotterySystemsList({lottery:selectedLottery, setReturn: setLotterySystems, setLoading:setLoadingSim});
        }
      }, [selectedLottery, systemResult]);




        
       
        const generateColumns = (data) => {
            const maxNumber = Math.max(...data.flat());
            const columns = [];
            columns.push({
              title: '#',
              dataIndex: 'index',
              key: 'index',
              render: (text, record, index) => <strong>{index + 1}</strong>,
              align: 'right'
            });
            for (let i = 1; i <= maxNumber; i++) {
                const index = i;
                columns.push({
                    title: `S${index} (${countNumberOccurrences(data, index)})`,
                    dataIndex: `col${index}`,
                    key: `col${index}`,
                    render: (text, record) => (
                        <span>{record.includes(index) ? index : null}</span>
                    ),
                    
                   align: 'center',
                });
            }
        
            return columns;
        };

        function checkRowsLength (data){
            const isSameLength = data.every(row => row.length === data[0].length);
            return isSameLength;
        };



        const countNumberOccurrences = (data, number) => {
            let count = 0;
            data.forEach((row) => {
                if (row.includes(number)) {
                    count++;
                }
            });
            return count;
        };

        const handleSave = () => {
            lotterySystemSave({system:systemData, numbersinrow:systemData[0].length, systemnumbers:systemMaxNumber, rows:systemTotalRows, systemname:systemName, makepublic:makepublic, id:systemId, setReturn:setSystemResult});
        }

        useEffect(() => {
            if (systemData.length>0 && checkRowsLength(systemData)){
                const rows=systemData.length;
                const maxNumber=Math.max(...systemData.flat());
                //const rowlength=systemData[0].length;
                setSystemTotalRows(rows);
                setSystemMaxNumber(maxNumber);
                //if (systemName===''){
                //setSystemName('System_R'+rows+'_N'+rowlength+'_M'+maxNumber);
                //}
                console.log(systemData)
            }
            

        }, [systemData]);

        const generateSummary = (data) => {
            console.log(data)
            if (data.length === 0) {
                return [];
            }
            const maxNumber = Math.max(...data.flat());
            const summary = [];
            for (let i = 1; i <= maxNumber; i++) {
                const count = countNumberOccurrences(data, i);
                summary.push({
                    number: i,
                    count: count,
                });
            }
            return summary;
        };

        //const summaryData = generateSummary(systemData);

        const handleTextareaChange = (e) => {
            //console.log(e)
            const inputString = e.target.value;
            //const arrayOfArrays = datastring.trim().split('\n').map(row => row.trim().split(/\s+/).map(Number));
            const arrayOfArrays = inputString.trim().split('\n').map(row => row.trim().split(/[ \t,;]+/).map(Number));

            console.log(arrayOfArrays)
            setSystemId(0);
            if (arrayOfArrays.length > 0) {
               lotterySystemCheck({system:arrayOfArrays, setReturn:setSystemResult, setLoading:setSystemResultLoading});
            }
            
            setSystemData(arrayOfArrays);
        }

        useEffect(() => {
            console.log("systemResult", systemResult)
            if (systemResult === null || systemResult === undefined) {
                return;
              }
            

            setSystemId(systemResult.systemid);
            let mcheckData=[];
            setGaranteedNumbers(systemResult.checked.garanties)
            for (let key in systemResult.checked.result) {
              if (key>2){
                systemResult.checked.result[key].forEach((item, index) => {
                  let row = {'Hits': key, ...item };
                  mcheckData.push(row);
                });
              }
            }
              console.log("mcheckData", mcheckData)
            setCheckData(mcheckData);
        }, [systemResult]);


        useEffect(() => { 
            if (selectedSystem === null || selectedSystem === undefined) {
                return;
              }
            setSystemData(JSON.parse(selectedSystem.system));
              setSystemName(selectedSystem.name);
              setSystemId(selectedSystem.id);
              setMakePublic(selectedSystem.public);
              setSystemMaxNumber(selectedSystem.systemnumbers);
              setSystemTotalRows(selectedSystem.rows);
              if (selectedSystem.checked !== null && selectedSystem.checked !== undefined){

                let systemResult2 = JSON.parse(selectedSystem.checked)
                setGaranteedNumbers(systemResult2.garanties)
                let mcheckData=[];
            for (let key in systemResult2.result) {
              if (key>2){
              systemResult2.result[key].forEach((item, index) => {
                  let row = {'Hits': key, ...item };
                  mcheckData.push(row);
                });
              }
            }
                setCheckData(mcheckData);
                
              }else{
                setCheckData([]);
              }
              
        }, [selectedSystem]);

          useEffect(() => {
            console.log("checkData", checkData )
            if (checkData===undefined || checkData.length === 0) {
                return;
              }

            const mcheckColumns = Object.keys(checkData[0]).map(key => ({
                title: key,
                dataIndex: key,
                key: key,
                align: 'center',
                className: 'header-bold',
                render: (text) => {
                    if (key === 'Hits') {
                      return <strong>{text}</strong>;
                    } else {
                      return <div>{text}</div>;
                    }
                  }
              }));

                console.log("mcheckColumns", mcheckColumns)
                setCheckColumns(mcheckColumns);
          }, [checkData]);
          
          
          checkColumns.sort((a, b) => b.title === 'Hits' ? 1 : -1);

          const handleSystemNameChange = (value) => {
            getlotterySystem({lottery:selectedLottery, systemid:value, setReturn: setSelectedSystem})
          }

           let lotterycost = 0;
           let currency = '';
    console.log("lotteryListData", lotteryListData)
     if (lotteryListData !== undefined && lotteryListData.hasOwnProperty(selectedLottery)) {
      lotterycost = lotteryListData[selectedLottery].costper;
      currency = lotteryListData[selectedLottery].currency;
   }

   const handleSearch = (value) => {
   setSearchTerm(value);
};

const handleKeyPress = (event) => {
  const keyCode = event.charCode;
  const isAllowedKey = (keyCode >= 48 && keyCode <= 57) || keyCode === 32 || keyCode === 44 || keyCode === 59 || keyCode === 9 || keyCode === 13;

  if (!isAllowedKey) {
    event.preventDefault();
  }
};

          const tabItems = [
            {key: '1', label: t.systemload, children: <>
                <Select showSearch onSearch={handleSearch}
                filterOption={(input, option) =>
    lotterySystems.find(system => system.id === option.value).name.toLowerCase().includes(input.toLowerCase())
  }
   placeholder={t.selectsystem} style={{ width: '400px' }} onChange={(value) => handleSystemNameChange(value)}>
  {lotterySystems.map(system => {
    return (
  

      <Select.Option key={system.id} value={system.id} style={{ whiteSpace: 'wrap', width:'100%'}}> 
        <div>{system.name}</div>
        <small className='selectdescription'> {`${t.numbersinrow}: ${system.numbersinrow}, ${t.cost}: ${currency}${system.cost === null || system.cost === 0 ? (system.rows * lotterycost).toFixed(2) : system.cost.toFixed(2)}, ${t.rows}: ${system.rows}, ${t.systemnumbers}: ${system.systemnumbers}`}</small>
      </Select.Option>
    )
  })}
</Select>
            </>
              
          
          },
          {
            key: '2', label: t.systemimportcreate, disabled:false, children: 
            <>
            <p style={{fontSize:10}}>{t.allowedseperators}</p>
                <TextArea placeholder={t.pastehere} onKeyPress={handleKeyPress} style={{width:300}} onChange={handleTextareaChange} /></>
          }
          
          ];
      
          const garanteedNumbersKeys = garanteedNumbers ? Object.keys(garanteedNumbers).filter(key => garanteedNumbers[key] && key > 2) : [];

          const handleTabSwitch = (key) => {
            setSelectedTab(key);
            setCheckData([]);
            setSystemData([]);
            setSystemId(0);
            setSystemName('');
          }
          const handleSystemName = () => (e) => {
            setSystemName(e.target.value);
          }

        return (
            <>
            {getGlobalValue("loggedIn") ? (<>


              <div style={{ height: '100vh', width: '99vw', paddingTop:0 }}>
                <h2>Systems</h2>
            <Tabs defaultActiveKey={selectedTab} items={tabItems} onChange={handleTabSwitch}/>
            
                <Row gutter={16}>
                    
                <Col span={12}>   
                        
                        {systemData.length > 0 ? (
<>                            <h2>{t.system}</h2>
                            <div className='summary'>
                             
                            <Input style={{width:200}} placeholder="System Name" value={systemName} defaultValue={systemName} onChange={handleSystemName()}/>
                            <Checkbox checked={makepublic} onChange={(e) => setMakePublic(e.target.checked)}>{t.makepublic}</Checkbox>
                            <Button onClick={handleSave}>{systemId>0 ? t.update:t.save}</Button>
                            
                            <span>{t.systemnumbers}: {Math.max(...systemData.flat())} {t.totalrows}: {systemData.length}</span>
                            
                            </div>
                            
                           
                        <Table
                            dataSource={systemData}
                            columns={generateColumns(systemData)}
                            pagination={false}
                            size="small"
                            style={{ width: 'fit-content' }}
                        />
                      
                 
                        </>) : (
                        <p>{selectedTab == 1  ? t.selectsystemtoview : t.addrowstosystem}</p>
                        )}
                       </Col> 
                       <Col span={12} >
                        <h2>{t.winnings}</h2>
                        {systemResultLoading ? (<> <Spin /></>) : (
                        checkData.length > 0 ? (<>
                            
                          <p>{t.garanteed}: {garanteedNumbersKeys.join(', ')}</p>
                <Table pagination={false} style={{ width: 'fit-content' }} size="small" dataSource={checkData} columns={checkColumns} />
                </>
                        ):(<p>{t.nodata}</p>)
                        )}
                </Col >
                        </Row>
                        </div>
            </>
        ): (
        <p><a href="/login">{t.login}</a> {t.or} <a href="/register">{t.register}</a> {t.needtosignin}</p>
      
      )}
      </>
      );
      
    
     

}

export default SystemBuilder;