// components/Home.js
import React, { useContext } from 'react';
import styled from 'styled-components';
import LanguageContext from '../LanguageContext';
import {Button} from 'antd';

const BackgroundContainer = styled.div`

  width: 100%;
  background-image: url('/images/background.webp');
  background-position: 0 -50px; 
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #eaebef;
`;


const Container = styled.div`
  
  background: rgba(255, 255, 255,0.5);
  
  color:black ;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  width: 100%;

  text-align: center;
`;

const Title = styled.h1`
  font-size: 2.5em;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  max-width: 400px;
  padding-bottom: 0;
 
`;

const TitleSpan = styled.span`
  font-size: 1em;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  max-width: 400px;
  padding-bottom: 0;
  background: -webkit-linear-gradient(270deg, #e6bf7a, #bf8c4f);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

`;

const Subtitle = styled.h2`
  font-size: 1.5em;
  font-weight: 200;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 50%;
  margin-top: 16px;
  display: flex;
  text-shadow: 0 0 20px rgba(255, 255, 255, 0.9);
`;
const FeatureContainer = styled.div`
grid-column-gap: 40px;
  grid-row-gap: 40px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;

  z-index: 200;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 81px;
  display: grid;
  max-width: 50%;
  
  `;

const FeatureMainTitle = styled.h2`
  font-size: 2.2em;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  max-width: 400px;
  padding-bottom: 0;
  text-shadow: 0 0 20px rgba(255, 255, 255, 0.9);
`;

const FeatureSingle = styled.div`

  z-index: 200;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 81px;
  display: block;
  max-width: 50%;
  
  `;

const Feature = styled.div`
  font-size: 1.5em;
  font-weight: 200;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 300;
  text-align: center;
  display: flex;
  background: rgba(255, 255, 255,0.9);
  border-radius: 8px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  box-shadow: #36476c26 0 4px 4px;
  padding: 10px;
  padding-bottom: 30px;
`;

const FeatureTitle = styled.h3`
  color: #173659;
  font-weight: 200;
  margin: 0;
`;

const FeatureSubtitle = styled.h2`;
    font-weight: 400;
   max-width: 50%;
   font-size: 1.2em;
   margin: 0;
   padding: 10px;
   text-shadow: 0 0 5px rgba(255, 255, 255, 0.9);
`;

const FeatureContent = styled.p`
  color: #000000;
  font-size: 0.75em;
 
`;

const Footer = styled.footer`
  height: 200px;
  width: 100%;
  background-color: #173659;
  color: #ddb370;
  text-align: center;
  padding: 10px 0;
`;

const FooterLink = styled.a`
  color: #ddb370;
  margin: 0 15px;
  text-decoration: none;
  
  &:hover {
    text-decoration: underline;
  }
`;


const CoolButton = styled(Button)`
  background-color: #c08d4f;
  color: white;
  border: none;
  
  font-size: 1em;
  border-radius: 4px;
  cursor: pointer;
  padding: 0px 10px 10px;
  &:hover {
    background-color: #a5733d;
  }
`;

const Spacer = styled.div`
  height: 50px;
`;

const Section = styled.div`

  display: flex;  
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90vh;
  `;


function Home() {

  const {t} = useContext(LanguageContext);

  return (<>
  <BackgroundContainer>
    <Container>
      <Section >
      <Title>{t.home.title}<TitleSpan><br/>{t.home.titlespan}</TitleSpan>
      
      </Title>
      <Subtitle>{t.home.subtitle}</Subtitle>
      </Section>
      
      
      <FeatureMainTitle>{t.home.homeStrategyTitle}</FeatureMainTitle>
      
      <img alt="Strategies" style={{width:'70%'}} src="/images/strategy.png"></img>
      <FeatureSubtitle>{t.home.homeStrategysubtitle}</FeatureSubtitle>
      <FeatureContainer>
        
        <Feature><FeatureTitle>{t.home.homeStrategyFilters}</FeatureTitle>
        <FeatureContent>{t.home.homeStrategyFiltersContent}<br/>

          <ul style={{textAlign:'left'}}>
          {t.home.homeStrategyFiltersList.map((item) => (
            <li>{item}</li>
          ))}
        
          </ul>
        </FeatureContent>
        
        
        </Feature>
        <Feature><FeatureTitle>{t.home.homeStrategySimulator}</FeatureTitle>
        <FeatureContent>{t.home.homeStrategySimulatorContent}<br/>
          <ul style={{textAlign:'left'}}>
          {t.home.homeStrategySimulatorList.map((item) => (
            <li>{item}</li>
          ))}
          </ul></FeatureContent>
        
        
        </Feature>
        
      </FeatureContainer>

      <Spacer/>

      <FeatureMainTitle>{t.home.homeNumberPatternsTitle}</FeatureMainTitle>
      <FeatureSubtitle>{t.home.homeNumberPatternsSubtitle}</FeatureSubtitle>
      <img alt="Number Patterns" style={{width:'50%'}} src="/images/numberpatterns.png"></img>
      
      <Spacer/>

       <FeatureMainTitle>{t.home.homeSystemBuilderTitle}</FeatureMainTitle>
       <FeatureSubtitle>{t.home.homeSystemBuilderSubtitle}</FeatureSubtitle>
      <img alt="System Builder" style={{width:'50%'}} src="/images/systembuilder.png"></img>
      
      <Spacer/>

      <FeatureMainTitle>{t.home.homeNumberStatisticsTitle}</FeatureMainTitle>
      <FeatureSubtitle>{t.home.homeNumberStatisticsSubtitle}</FeatureSubtitle>
      <img alt="Statistics" style={{width:'70%'}} src="/images/statistics.png"></img>
      
      <Spacer/>

      <FeatureMainTitle>{t.home.homeSupportedLotteries}</FeatureMainTitle>
      <FeatureSubtitle>{t.home.homeSupportedLotteriesContent}</FeatureSubtitle>
      
      <FeatureSingle>
        
        <Feature>
        <FeatureContent><>
          <ul style={{ display: 'grid', listStyle: 'none', gap:20, padding: '0',
    margin: '0',  gridTemplateColumns: '1fr 1fr',textAlign:'left'}}>
          {t.home.homeSupportedLotteriesList.map((item) => (
            <li style={{padding: '20px', textAlign:'center',
            border: '1px solid #ccc',
            borderRadius: '5px',
            backgroundColor: '#f9f9f9'}}>{item}</li>
          ))}
          </ul>
          <h3>{t.home.homeComingSoon}</h3>
       
          <ul style={{ display: 'grid', padding: '0',
    margin: '0',listStyle: 'none', gap:10,  gridTemplateColumns: '1fr 1fr',textAlign:'left'}}>
          {t.home.homeComingSoonLotteriesList.map((item) => (
            <li style={{padding: '20px',textAlign:'center',
            border: '1px solid #ccc',
            borderRadius: '5px',
            backgroundColor: '#f9f9f9'}}>{item}</li>
          ))}
          </ul>
          </>
        </FeatureContent>

      </Feature>
        
       
        

      </FeatureSingle>

      <Spacer/>

      <FeatureMainTitle>{t.home.homePricingTitle}</FeatureMainTitle>
      <FeatureSubtitle>{t.home.homePricingSubtitle}</FeatureSubtitle>
      <FeatureContainer>
        
        <Feature><FeatureTitle style={{fontWeight:600}}>{t.home.homePricingFree}</FeatureTitle>
        <FeatureContent>{t.home.homePricingFreeContent}<br/><br/>
        <CoolButton  href="/register">{t.home.homePricingFreeContentButton}</CoolButton>
        </FeatureContent>
        
        
        </Feature>
        <Feature><FeatureTitle style={{fontWeight:600}}>{t.home.homePricingPaid}</FeatureTitle>
        <FeatureContent>{t.home.homePricingPaidContent}<br/>
        <br/>

          <CoolButton  href="/subscribe">{t.home.homePricingPaidContentButton}</CoolButton>
          
          </FeatureContent>
        
        
        </Feature>
        
      </FeatureContainer>

    <Footer>
    <FooterLink href="/imprint">{t.home.imprint}</FooterLink>
    <FooterLink href="/privacy">{t.home.privacypolicy}</FooterLink>
    <FooterLink href="/terms">{t.home.termandservices}</FooterLink>
  </Footer>
  </Container>
  </BackgroundContainer>
  </>
  );
}

export default Home;