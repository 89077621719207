import React, { useEffect, useState } from 'react';
import { ArrowDownOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Table, Button } from 'antd';

const NumberSelect = ({numbers, maxNumber, numRows, numCols, onChange}) => {

    const [selectedCells, setSelectedCells] = useState([]);
    const [selectedCellsNumbers, setSelectedCellsNumbers] = useState([]);


    
    //const numRows = 7; // Define the number of rows
    //const numCols = 7; // Define the number of columns

    useEffect(() => {

        if (numbers === null || numbers === undefined || numbers==="") {return}

       
        const numbersArray = numbers.split(',').map(Number);
        console.log("numbersArray", numbersArray)
        if (numbersArray.length === 0) {return}
        const uniqueSelectedCellsNumbers = [...new Set(numbersArray)];
        setSelectedCellsNumbers(uniqueSelectedCellsNumbers);
        
     
       
    }, []);

    useEffect(() => {
        const uniqueSelectedCellsNumbers = [...new Set(selectedCellsNumbers)];
        //setSelectedCellsNumbers(uniqueSelectedCellsNumbers);
        //console.log("uniqueSelectedCellsNumbers", uniqueSelectedCellsNumbers, uniqueSelectedCellsNumbers.sort(function (a, b) {  return a - b;  }))
        onChange(uniqueSelectedCellsNumbers.sort(function (a, b) {  return a - b;  }).join(','))
    }, [selectedCellsNumbers]);

    const handleCellClick = (rowIndex, colIndex, text) => {
        //console.log("rowIndex", rowIndex, "colIndex", colIndex, "text", text)
        if (rowIndex === 0 || text=== undefined) {return}
        const cell = `${rowIndex}-${colIndex}`;
        
        setSelectedCellsNumbers(prevSelectedCells => {
             if (prevSelectedCells.includes(text)) {
                return prevSelectedCells.filter(c => c !== text);
            } else {
                return [...prevSelectedCells, text];
            }
        });
        setSelectedCells(prevSelectedCells => {
            if (prevSelectedCells.includes(cell)) {
                return prevSelectedCells.filter(c => c !== cell);
            } else {
                return [...prevSelectedCells, cell];
            }
        });
    };

    const handleRowClick = (rowIndex) => {
        setSelectedCells(prevSelectedCells => {
            const newSelectedCells = Array.from({ length: numCols }, (_, i) => `${rowIndex}-${i}`);
            return [...prevSelectedCells, ...newSelectedCells];
        });

        const rowData = tableData[rowIndex];

        // Extract the cell values
        const cellValues = Object.values(rowData).filter(value => typeof value === 'number' && value > 0);

        // Add the cell values to selectedCellsNumbers
        setSelectedCellsNumbers(prevSelectedCellsNumbers => [...prevSelectedCellsNumbers, ...cellValues]);
        
    };

    const handleColumnClick = (colIndex) => {
    setSelectedCells(prevSelectedCells => {
        // Update the length of the array to numRows + 1 to account for the extra row at the top
        const newSelectedCells = Array.from({ length: numRows + 1 }, (_, i) => `${i}-${colIndex}`);
        return [...prevSelectedCells, ...newSelectedCells];

        
    });
    
    const newSelectedCellsNumbers = tableData.map((row, index) => {
        if (index === 0) return null;
        return row[`col${colIndex}`];
    }).filter(value => typeof value === 'number' && value > 0);
    

    setSelectedCellsNumbers(prevSelectedCellsNumbers => [...prevSelectedCellsNumbers, ...newSelectedCellsNumbers]);

};



    const generateTableData = (numRows, numCols) => {
    const data = [];
    let count = 1;

    for (let i = 0; i < numRows; i++) {
        const row = {};
        for (let j = 0; j < numCols; j++) {
            if (count > maxNumber) break;
            row[`col${j}`] = count++;
        }
        data.push(row);
    }

    // Add an extra row at the top
    const topRow = {};
    for (let j = 0; j < numCols; j++) {
        //const minNumber = data[0][`col${j}`];
        //const maxNumber = data[data.length - 1][`col${j}`];
        topRow[`col${j}`] = <Button style={{border:'none', backgroundColor:'transparent', fontSize:10, padding:5}} onClick={() => handleColumnClick(j)}><ArrowDownOutlined/></Button>;
    }
    data.unshift(topRow);

    return data;
};

const handleReset = () => { 
    
    
    
    if (selectedCellsNumbers.length === 0) {
    let count = 1;
    let newData=[];
        for (let i = 0; i < numRows; i++) {
           
            for (let j = 0; j < numCols; j++) {
                
                newData.push(count);
                count++;
            }
            
        }
    setSelectedCellsNumbers(newData)
    }else{
    setSelectedCellsNumbers([]); 
    }

}



const columns = Array.from({ length: numCols }, (_, i) => ({
    title: `Column ${i + 1}`,
    dataIndex: `col${i}`,
    key: `col${i}`,
    render: (text, record, rowIndex) => {
        
        const isHighlighted = selectedCellsNumbers.includes(text);
        return {
            props: {
                style: { backgroundColor: isHighlighted ? 'lightgreen' : 'inherit', cursor: 'pointer'},
                onClick: () => handleCellClick(rowIndex, i, text),
                className:'cell',
            },
            children: <div className="center-content" >{text}</div>,
        };
    },
}));

    // Add an extra column to the left
    columns.unshift({
    title: '',
    dataIndex: 'activateRow',
    key: 'activateRow',
    align: 'right',
    className: 'cellFirstRow',
    render: (_, record, rowIndex) => {
        if (rowIndex === 0){
            return (<Button style={{border:'none', backgroundColor:'transparent', fontSize:10, padding:5, height:10}} onClick={() => handleReset()} >{selectedCellsNumbers.length>0 ? '-':'+'}</Button>)
        }  
        //const rowValues = Object.values(record); // Exclude the 'activateRow' value
        //const minNumber = Math.min(...rowValues);
        //const maxNumber = Math.max(...rowValues);
        return (
            <Button style={{border:'none', backgroundColor:'transparent', fontSize:10, padding:5, height:10}} onClick={() => handleRowClick(rowIndex)}><ArrowRightOutlined style={{fontSize:12}} /></Button>
        );
    },
});

    const tableData = generateTableData(numRows, numCols);

    return (<>
        <div style={{maxWidth:300, maxHeight:400}}>
        <Table
            dataSource={tableData}
            columns={columns}
            pagination={false}
            bordered
            showHeader={false}
        />
        </div>
        </>
    );
};

export default NumberSelect;