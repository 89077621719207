import React, { useContext } from 'react';
import LanguageContext from '../LanguageContext';
import './css/Register.css';

const Terms = () => {


    const { t} = useContext(LanguageContext);

    return (

        t.legal.terms
        
    );
}

export default Terms;