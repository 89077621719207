import React, { useContext } from 'react';
import LanguageContext from '../LanguageContext';
import './css/Register.css';


const Privacy = () => {

    const { t} = useContext(LanguageContext);

    return (t.legal.privacy
    
    
    
    )

}

export default Privacy;