import React, { useContext, useState }  from 'react';
import LanguageContext from '../../LanguageContext';
import dayjs from "dayjs";
import { ResponsiveLine } from '@nivo/line'
import { scaleLinear } from 'd3-scale';
import { interpolateRainbow } from 'd3-scale-chromatic';



function EvolutionGraph({dataSource}) {

    const { t } = useContext(LanguageContext);


    const [activeSeries, setActiveSeries] = useState([]);

    const legendOnClick = (id) => {
      //console.log(id);
      if (activeSeries.includes(id)) {
        
        setActiveSeries(activeSeries.filter(seriesId => seriesId !== id));
        

      }else{
        setActiveSeries([...activeSeries, id]);
        
      }
    };
/*
    const sortedData = useMemo(() => {
      if (activeSeries === null) {
          return dataSource; // no active series, no need to sort
      }
      // sort so that active series is last (and therefore on top)
      return [...dataSource].sort((a, b) => (a.id === activeSeries ? 1 : b.id === activeSeries ? -1 : 0));
  }, [dataSource, activeSeries]);
*/
    const getLineColor = (id) => {
        if (activeSeries.length === 0) {
            return interpolateRainbow(colorScale(id)); // default color
        }
        return activeSeries.includes(id) ? interpolateRainbow(colorScale(id)) : 'rgba(100, 100, 100, 0.1)'; // highlight active, dim others
    };




       
    const colorScale = scaleLinear()
    .domain([1, dataSource.length]) // Input range
    .range([0, 1]); // Output range

return (
    <>
    
    <div style={{ height: '100vh', width: '99vw' }}>
      <ResponsiveLine
        data={dataSource}
        
        colors={({ id }) => getLineColor(id)}
        margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
        xScale={{ type: 'time', format: '%Y-%m-%d', precision: 'day' }}
        yScale={{ type: 'linear', min: 'auto', max: 'auto' }}
        yFormat=""
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: '',
          legendOffset: 36,
          legendPosition: 'middle',
          truncateTickAt: 0,
          format: '%Y-%m-%d',
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: '',
          legendOffset: -40,
          legendPosition: 'middle',
          truncateTickAt: 0,
        }}
        tooltip={({ point }) => {
          return (
            <div style={{backgroundColor:'white',borderRadius:8, opacity:0.8, padding:5, boxShadow:'2px 2px 1px 2px rgb(0,0,0,0.5)'}}>
              <strong>{t.date}: {dayjs(point.data.x).format('MMM DD, YYYY')}</strong>
              <br />
              {t.number}: {point.serieId}
              <br />
              {t.totaldrawn}: {point.data.y}
            </div>
          );
        }}
        pointSize={8}
        
        pointBorderWidth={1}
        pointBorderColor={{ from: 'serieColor' }}
        
        pointLabelYOffset={-12}
        enableTouchCrosshair={true}
        useMesh={true}
        legends={[
          {
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 100,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: 'left-to-right',
            itemWidth: 80,
            itemHeight: 12,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: 'circle',
            symbolBorderColor: 'rgba(0, 0, 0, .5)',
            onClick: (data) => legendOnClick(data.id),
            effects: [
              {
                on: 'hover',
                style: {
                  itemBackground: 'rgba(0, 0, 0, .03)',
                  itemOpacity: 1
                }
              }
            ]
          }
        ]}
      />
    </div>
    </>
);



}

export default EvolutionGraph;